import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Layout } from 'containers';

const ItineraryThankYouPage = ({ pageContext }) => (
  <Layout page="itinerary-thank-you">
    <Helmet
      title="Thanks for Downloading Our Itinerary | Haka Tours, NZ"
      meta={[
        {
          name: 'description', content: 'Your copy of the Haka Tours itinerary will be sent immediately.',
        },
        { name: 'og:title', content: 'Thanks for Downloading Our Itinerary | Haka Tours, NZ' },
        {
          name: 'og:description', content: 'Your copy of the Haka Tours itinerary will be sent immediately.',
        },
        { name: 'robots', content: 'noindex' },
      ]}
    />
    <section className="l-section">
      <div className="l-container l-container--small u-text--center">
        <h1 className="c-heading c-heading--h1">Thanks!</h1>
        <p>Your copy of the Haka Tours itinerary will be sent immediately. If it hasn’t arrived, please check your spam and junk folders. Please
          contact us if it hasn’t arrived within the hour. We’re looking forward to helping your
          build your next adventure.
        </p>
        <br />
        <br />
        <p>
          <Link to={pageContext.absolute_slug} className="c-button c-button--primary c-button--wide">
            Back to tour
          </Link>
        </p>
      </div>
    </section>
  </Layout>
);

ItineraryThankYouPage.propTypes = {
  pageContext: PropTypes.any.isRequired,
};

export default ItineraryThankYouPage;
